import { LanguageVersion } from '@data/common/LanguageVersion';
import { EnvironmentType } from '@data/common/EnvironmentType';

export const environment = {
  name: EnvironmentType.DEV,
  production: true,
  apiUrl: 'https://api.weill-cornel-dev.apzumi.dev/api',
  defaultLanguage: LanguageVersion.EN,
  cognito: {
    userPoolId: 'ca-central-1_rpbkvlZ44',
    userPoolWebClientId: '1ontlh40kv9ls3qit9n7vndl3u',
    region: 'ca-central-1'
  }
};
